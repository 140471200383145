
import { ChipsStyles } from "@/app/ui/components/chips-v2/modules";
import { InternationalDocumentController } from "@/app/ui/controllers/InternationalDocumentController";
import { LocationController } from "@/app/ui/controllers/LocationController";
import { CountryData } from "@/domain/entities/Location";
import { ApiStateData } from "@/domain/entities/Api";
import { PaginationV2 } from "@/domain/entities/Pagination";
import { Options, Vue } from "vue-class-component";
import Checkbox from "primevue/checkbox";
import {
  convertArrayToString,
  parsingErrorResponse
} from "@/app/infrastructures/misc/Utils";
import {
  InternationalDocument,
  InternationalDocumentListEntities,
  RequestInternationalDocumentList
} from "@/domain/entities/InternationalDocument";
import debounce from "lodash/debounce";

@Options({
  components: {
    Checkbox,
  },
})
export default class InternationalDocumentList extends Vue {

  apiListData = new ApiStateData();
  listData: InternationalDocumentListEntities  = new InternationalDocumentListEntities();
  filter = {
    countryCode: "" as any,
    status: "" as any,
    search: "" as any,
  };

  mounted() {
    this.fetchCountry("")
    this.getInternationalDocumentList();
  }

  async getInternationalDocumentList() {
    this.apiListData.loading = true;
    try {
      const resp = await InternationalDocumentController.getInternationalDocumentList(
        new RequestInternationalDocumentList({
          countryCode: this.filter.countryCode,
          status: this.filter.status,
          search: this.filter.search,
          page: this.listData.pagination.page,
          isTotalData: true,
        })
      );
      this.listData = new InternationalDocumentListEntities({
        pagination: new PaginationV2({
          page: resp.pagination.page,
          limit: resp.pagination.limit,
          totalData: resp.pagination.totalData
        }),
        data: resp.data,
      });
      this.apiListData.errorType = "";
    } catch (error) {
      this.apiListData.errorType = parsingErrorResponse(error).type;
    } finally {
      this.apiListData.loading = false;
    }
  }

  fetchCountry(value: string) {
    if (value.length > 1 || !value) {
      LocationController.getCountryList({
        search: value,
        status: "active",
        page: 1,
        limit: 10
      });
    }
  }

  get title(): string {
    return (this.$route as any)?.meta?.title;
  }

  get isEmpty(): boolean {
    return !this.listData.data.length && this.sumFilter === 0;
    // add no filter
  }

  get sumFilter(): number {
    return (
      (this.filter.countryCode ? 1 : 0) +
      (this.filter.search ? 1 : 0) +
      (this.filter.status ? 1 : 0)
    );
  }

  get columns() {
    return [
      {
        name: "No.",
        styleHead: "w-12 text-left",
        render: (_: any, index: any) => {
          return `<div class="text-left">${
            index +
            1 +
            this.listData.pagination.limit * (this.listData.pagination.page - 1)
          }</div>`;
        },
      },
      {
        name: "Negara",
        styleHead: "w-40 text-left",
        render: (item: InternationalDocument) => {
          return `<div class="text-left">${item.country.name}</div>`;
        },
      },
      {
        name: "Kode Negara",
        styleHead: "w-24 text-left",
        render: (item: InternationalDocument) => {
          return `<div class="flex text-left">${item.country.code}</div>`;
        },
      },
      {
        name: "Komoditas lainnya",
        styleHead: "w-32 text-left",
        render: (item: InternationalDocument) => {
          return this.mapStatus(item.idcIsOtherCommodity);
        },
      },
      {
        name: "Email Penerima",
        styleHead: "w-36 text-left",
        render: (item: InternationalDocument) => {
          return this.mapStatus(item.idcIsReceiverEmail);
        },
      },
      {
        name: "Foto sebelum & sesudah packing",
        styleHead: "w-40 text-left",
        render: (item: InternationalDocument) => {
          return this.mapStatus(item.idcIsBeforeAndAfterPackingImage);
        },
      },
      {
        name: "Foto KTP",
        styleHead: "w-32 text-left",
        render: (item: InternationalDocument) => {
          return this.mapStatus(item.idcIsKtpImage);
        },
      },
      {
        name: "Foto NPWP",
        styleHead: "w-36 text-left",
        render: (item: InternationalDocument) => {
          return this.mapStatus(item.idcIsNpwpImage);
        },
      },
      {
        name: "Status",
        styleHead: "w-36 text-left",
        render: (item: InternationalDocument) =>
          ChipsStyles({ status: item.idcStatus }),
      },
    ];
  }

  mapStatus(status: boolean): string {
    return status ? '<div class="flex text-left text-red-lp-500">Wajib</div>' : '<div class="flex text-left text-gray-lp-800">Opsional</div>' 
  }

  onChangeAdvancedFilter = debounce(() => {
    this.listData.pagination.page = 1;
    this.getInternationalDocumentList();
  }, 500);

  onSearch(value: string) {
    Object.assign(this.filter, {
      search: value,
    });
    this.onChangeAdvancedFilter();
    
  }

  get searchValue() {
    return this.filter.search;
  }

  clearSearch() {
    Object.assign(this.filter, {
      search: "",
    });
    this.onChangeAdvancedFilter();
  }

  //sort by

  statusTitle = "";
  statusSelect = false;
  statusData = [
    { name: "Semua status", value: "" },
    { name: "Aktif", value: "active" },
    { name: "Tidak aktif", value: "inactive" }
  ];

  onOpenStatus() {
    this.statusSelect = true;
  }

  onCloseStatus() {
    this.statusSelect = false;
  }

  onSelectStatus(name: string, value: string) {
    Object.assign(this.filter, {
      status: value || "",
      });
      this.onChangeAdvancedFilter();
      this.statusTitle = name;
  }

  //country code
  countryCode = [];

  get isLoadingCountry() {
    return LocationController.isLoadingCountry;
  }
  get countryOptions() {
    const listData = LocationController.countryData.countryData
      .map(e => {
        return {
          name: e.code + " - " + e.name,
          value: e.code
        };
      });

    return listData;
  }

  onChangeCountry() {
    console.log(this.convertToString(this.countryCode, "value"))
    Object.assign(this.filter, {
      countryCode: this.convertToString(this.countryCode, "value") || "",
      });
    this.onChangeAdvancedFilter()
  }

  // Convert Value
  convertToString(data: Array<any>, key: string) {
    return convertArrayToString(data, key);
  }
};
