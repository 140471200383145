import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen relative font-montserrat" }
const _hoisted_2 = { class: "flex flex-row text-12px xxl:text-14px items-center w-full" }
const _hoisted_3 = { class: "font-semibold text-20px mr-8 whitespace-nowrap text-black-lp-200 capitalize" }
const _hoisted_4 = {
  key: 0,
  class: "flex flex-col sm:flex sm:flex-row pt-4 pb-4"
}
const _hoisted_5 = { class: "w-60" }
const _hoisted_6 = { class: "flex h-screen justify-center overflow-auto" }
const _hoisted_7 = { class: "overflow-y-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Search = _resolveComponent("Search")!
  const _component_MultipleSelectSearch = _resolveComponent("MultipleSelectSearch")!
  const _component_Select = _resolveComponent("Select")!
  const _component_TableV2 = _resolveComponent("TableV2")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Header, null, {
      default: _withCtx(() => [
        _createVNode("div", _hoisted_2, [
          _createVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1)
        ])
      ]),
      _: 1
    }),
    (!_ctx.apiListData.errorType)
      ? (_openBlock(), _createBlock("div", _hoisted_4, [
          _createVNode(_component_Search, {
            onInput: _ctx.onSearch,
            onClear: _ctx.clearSearch,
            value: _ctx.searchValue,
            minChar: "2",
            class: "w-300px mb-1",
            placeholder: "Cari negara/kode negara"
          }, null, 8, ["onInput", "onClear", "value"]),
          _createVNode("div", _hoisted_5, [
            _createVNode(_component_MultipleSelectSearch, {
              options: _ctx.countryOptions,
              modelValue: _ctx.countryCode,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.countryCode = $event)),
              modalTitle: "Negara",
              keyName: "name",
              keyValue: "value",
              isLoading: _ctx.isLoadingCountry,
              placeholder: "Semua negara",
              onFilter: _ctx.fetchCountry,
              onChange: _ctx.onChangeCountry
            }, null, 8, ["options", "modelValue", "isLoading", "onFilter", "onChange"])
          ]),
          _createVNode(_component_Select, {
            dataSelect: _ctx.statusData,
            onOpenSelect: _ctx.onOpenStatus,
            onCloseSelect: this.onCloseStatus,
            onChooseSelect: _ctx.onSelectStatus,
            value: _ctx.statusTitle,
            isOpenMenu: _ctx.statusSelect,
            isDefaultBlack: false,
            class: "ml-4 w-40"
          }, null, 8, ["dataSelect", "onOpenSelect", "onCloseSelect", "onChooseSelect", "value", "isOpenMenu"])
        ]))
      : _createCommentVNode("", true),
    _createVNode("div", _hoisted_6, [
      _createVNode("div", _hoisted_7, [
        _createVNode(_component_TableV2, {
          class: "my-2",
          borderBottom: false,
          isEmpty: _ctx.isEmpty,
          columns: _ctx.columns,
          data: _ctx.listData.data,
          loading: _ctx.apiListData.loading,
          errorCause: _ctx.apiListData.errorType,
          emptyHeaderMessage: "",
          emptyMessage: "Belum ada syarat dokumen internasional",
          pagination: _ctx.listData.pagination,
          "onUpdate:pagination": _cache[2] || (_cache[2] = ($event: any) => (_ctx.listData.pagination = $event)),
          onRequest: _ctx.getInternationalDocumentList,
          onTryAgain: _ctx.getInternationalDocumentList,
          paginationStyle: "v3",
          isPinnedScrollBottom: ""
        }, null, 8, ["isEmpty", "columns", "data", "loading", "errorCause", "pagination", "onRequest", "onTryAgain"])
      ])
    ])
  ]))
}