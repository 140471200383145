import { InternationalDocumentPresenter } from "@/app/ui/presenters/InternationalDocumentPresenter";
import { InternationalDocumentMapper } from "@/data/persistences/mappers/InternationalDocumentMapper";
import { container } from "tsyringe";
import { InternationalDocumentEndpoints } from "../../endpoints/horde/InternationalDocumentEndpoints";
import { InternationalDocumentApiRepository } from "../../repositories/api/InternationalDocumentApiRepository";
import ApiService from "../../services/ApiService";

export class InternationalDocumentComponent {
  public static init() {
    container.register<InternationalDocumentApiRepository>(InternationalDocumentApiRepository, {
      useFactory: d => {
        return new InternationalDocumentApiRepository(
          d.resolve(ApiService),
          d.resolve(InternationalDocumentMapper),
          d.resolve(InternationalDocumentEndpoints)
        );
      }
    });
    container.register<InternationalDocumentMapper>(InternationalDocumentMapper, {
      useClass: InternationalDocumentMapper
    });
    container.register<InternationalDocumentPresenter>(InternationalDocumentPresenter, {
      useFactory: d => {
        return new InternationalDocumentPresenter(d.resolve(InternationalDocumentApiRepository));
      }
    });
  }
}
