import { InternationalDocumentComponent } from "@/app/infrastructures/dependencies/modules/InternationalDocumentComponent";
import {
    InternationalDocument,
    InternationalDocumentListEntities,
    RequestInternationalDocumentList,
    RequestInternationalDocumentDetail
} from "@/domain/entities/InternationalDocument";
import store from "@/store";
import { container } from "tsyringe";
import { Action, Module, Mutation, VuexModule, getModule } from "vuex-module-decorators";
import { InternationalDocumentPresenter } from "../presenters/InternationalDocumentPresenter";

interface InternationalDocumentState {
    internationalDocumentList: InternationalDocumentListEntities;
    internationalDocumentData: InternationalDocument;
    isLoading: boolean;
    internationalDocumentDetail: RequestInternationalDocumentDetail
}

InternationalDocumentComponent.init();

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "international-document"
})
class InternationalDocumentStore extends VuexModule implements InternationalDocumentState {
  public internationalDocumentList: InternationalDocumentListEntities = new InternationalDocumentListEntities();
  public internationalDocumentData: InternationalDocument = new InternationalDocument();
  public isLoading = false;
  public internationalDocumentDetail: RequestInternationalDocumentDetail = new RequestInternationalDocumentDetail();

  @Action
  public getInternationalDocumentList(params: RequestInternationalDocumentList) {
    const presenter = container.resolve(InternationalDocumentPresenter);
    return presenter.getInternationalDocumentList(params);
  }

  @Action
  public async getInternationalDocumentDetail(params: {
    origin: number,
    dest: number
  }) {
    const presenter = container.resolve(InternationalDocumentPresenter);
    const res = await presenter.getInternationalDocumentDetail(params);
    this.setErrorDocumentInternational(res);
  }

  @Mutation 
  public setErrorDocumentInternational(data: RequestInternationalDocumentDetail) {
    this.internationalDocumentDetail = data;
  }
}

export const InternationalDocumentController = getModule(InternationalDocumentStore);